































































import { Component, Vue } from "vue-property-decorator";
import firebase from "@/plugins/firebase";
import "@/types";

const FileUploaderBase = Vue.extend({
  props: {
    app: {
      type: Object,
      required: true
    },
    scholarship: {
      type: Object,
      required: true
    }
  }
});

@Component
export default class FileUploader extends FileUploaderBase {
  files: File[] = [];

  fileListDefault = [
    {
      id: 1,
      title: "Başvuru Formu *",
      name: "BasvuruFormu",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Islak imzalı olmalıdır.</li><li><a href='/files/kahev-burs-basvuru-formu.pdf' target='_blank' class='secondary--text'>Başvuru formunu buradan indirebilirsiniz.</a></li><li>Lütfen göndereceğiniz dosyada, başvuru formunun iki sayfasının da bulunduğundan emin olunuz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 2,
      title: "KAHEV Referans Üye Taahhütnamesi *",
      name: "ReferansUyeTaahhutnamesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Islak imzalı olmalıdır.</li><li><a href='/files/kahev-burs-referans-taahhutnamesi.pdf' target='_blank' class='secondary--text'>Referans üye taahhütnamesini buradan indirebilirsiniz.</a></li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 3,
      title: "Öğrenci Belgesi *",
      name: "OgrenciBelgesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc: "",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 4,
      title: "Öğrenci Taahhütnamesi *",
      name: "OgrenciTaahhutnamesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Islak imzalı olmalıdır.</li><li>Öğrenci, 18 yaşından küçükse velisi tarafından imzalanacak.</li><li><a href='/files/kahev-burs-ogrenci-taahhutnamesi.pdf' target='_blank' class='secondary--text'>Öğrenci taahhütnamesini buradan indirebilirsiniz.</a></li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 5,
      title: "Vukuatlı Nüfus Kayıt Örneği *",
      name: "VukuatliNufusKayitOrnegi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Tüm aile bireylerini içermelidir.</li><li>e-Devletten alınabilir.</li><li>Anne veya baba alırsa tüm aile bireyleri görünür.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 6,
      title: "Adli Sicil Kaydı *",
      name: "AdliSicilKaydi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>e-Devletten alınabilir.</li><li>18 yaş üstü tüm aile bireyleri için alınmalıdır. Evli kız kardeşlere gerek yoktur.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 7,
      title: "Öğrencinin İkamet Şeklini Gösteren Belge *",
      name: "OgrencininIkametSekliniGosterenBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Öğrenci yurtta kalıyorsa yurt belgesi gönderilmelidir.</li><li>Öğrenci kirada kalıyorsa kira sözleşmesi gönderilmelidir.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 8,
      title: "Aile İkamet Şeklini Gösteren Belge *",
      name: "AileIkametSekliniGosterenBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Öğrencinin aile evi kira ise kira sözleşmesi gönderilmelidir.</li><li>Öğrencinin aile evi kendilerinin ise tapu gönderilmelidir.</li><li>Akraba evinde kirasız vs. kalma gibi belgelenemeyen durumları belirtmek için referans üyemiz kendi el yazısıyla, imzalı ve kaşeli bir dilekçe yazabilir.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 9,
      title: "Taşınır/Taşınmaz Mal Beyanları",
      name: "MalBeyanlari",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Taşınır mal beyanı, e-devlette <strong>Emniyet Müdürlüğü</strong> sayfasındaki <strong>Adıma Tescilli Araç Sorgulama</strong> ekranından alınabilir.</li><li>Taşınmaz mal beyanı, e-devlette <strong>Tapu ve Kadastro</strong> sayfasındaki <strong>Tapu Bilgileri Sorgulama</strong> ekranından alınabilir.</li><li>Kişi isminin net olarak göründüğü ekran görüntüsü kabul edilir.</li><li>Eğer bağ, bahçe, tarla, arsa vb. var ise değer tespit belgesi (16. Madde) <strong>zorunludur</strong>.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 10,
      title: "Okuyan Kardeşlerin Öğrenci Belgeleri",
      name: "OkuyanKardeslerinOgrenciBelgeleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 11,
      title: "Öğrencinin Geliri Olmadığına Dair Belge",
      name: "OgrencininGeliriOlmadiginaDairBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>e-Devletteki <strong>SGK Bilgileri</strong> sayfasından alınabilir.</li><li>Öğrenci 18 yaş altı ise bu belgeye gerek yoktur.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 12,
      title: "Ev Kredisi Borç Dökümü",
      name: "EvKredisiBorcDokumu",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Eğer ailenin ödemekte olduğu ev kredisi borcu varsa beyan edilmelidir. Diğer krediler değerlendirmeye <strong>dahil değildir</strong>.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 13,
      title: "Anne ve Babanın Ayrı Olduğunu Gösteren Belge",
      name: "AnneBabaninAyriOldugunuGosterenBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc: "",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 14,
      title: "Maaş Bordroları ve SGK Dökümleri",
      name: "MaasBordrolariVeSgkDokumleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Anne ve babanın <strong>son 3 aya ait maaş bordrosu</strong> ve e-devletten alınacak <strong>son 6 aya ait özet SGK 4a/4B/4C Dökümü</strong></li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 15,
      title: "Serbest Meslek Gelir Beyannameleri",
      name: "SerbestMeslekGelirBeyannameleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Anne ve/veya baba serbest çalışan ise gelir beyannameleri belirtilmelidir.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 16,
      title: "Tarım Geliri Belgeleri",
      name: "TarimGeliriBelgeleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Eğer öğrencinin ailesi gelirini tarımdan elde ediyor ise <strong>çiftçi ilçe tarım ziraat odası gelir durumu belgesi</strong> ve <strong>kaç dönüm arazisi olduğuna dair belge</strong> beyan edilmelidir.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 16,
      title: "Değer Tespit Belgesi",
      name: "GelirTespitBelgesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Eğer taşınmaz mal beyanında ev, arsa, bağ, bahçe vb. çıktıysa ilgili <strong>belediyeden</strong> alınacak <strong>değer tespit belgesi</strong> sunulmalıdır.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    }
  ];

  fileListDershane = [
    {
      id: 1,
      title: "Başvuru Formu *",
      name: "BasvuruFormu",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Islak imzalı olmalıdır.</li><li><a href='/files/kahev-burs-basvuru-formu.pdf' target='_blank' class='secondary--text'>Başvuru formunu buradan indirebilirsiniz.</a></li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 2,
      title: "KAHEV Referans Üye Taahhütnamesi *",
      name: "ReferansUyeTaahhutnamesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Islak imzalı olmalıdır.</li><li><a href='/files/kahev-burs-referans-taahhutnamesi.pdf' target='_blank' class='secondary--text'>Referans üye taahhütnamesini buradan indirebilirsiniz.</a></li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 3,
      title: "Öğrenci Belgesi *",
      name: "OgrenciBelgesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc: "",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 4,
      title: "Öğrenci Taahhütnamesi *",
      name: "OgrenciTaahhutnamesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Islak imzalı olmalıdır.</li><li>Öğrenci, 18 yaşından küçükse velisi tarafından imzalanacak.</li><li><a href='/files/kahev-burs-ogrenci-taahhutnamesi.pdf' target='_blank' class='secondary--text'>Öğrenci taahhütnamesini buradan indirebilirsiniz.</a></li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 5,
      title: "Vukuatlı Nüfus Kayıt Örneği *",
      name: "VukuatlıNufusKayitOrnegi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Tüm aile bireylerini içermelidir.</li><li>e-Devletten alınabilir.</li><li>Anne veya baba alırsa tüm aile bireyleri görünür.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 6,
      title: "Adli Sicil Kaydı *",
      name: "AdliSicilKaydi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>e-Devletten alınabilir.</li><li>18 yaş üstü tüm aile bireyleri için alınmalıdır. Evli kız kardeşlere gerek yoktur.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 7,
      title: "Öğrencinin İkamet Şeklini Gösteren Belge *",
      name: "OgrencininIkametSekliniGosterenBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Öğrenci yurtta kalıyorsa yurt belgesi gönderilmelidir.</li><li>Öğrenci kirada kalıyorsa kira sözleşmesi gönderilmelidir.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 8,
      title: "Aile İkamet Şeklini Gösteren Belge *",
      name: "AileIkametSekliniGosterenBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Öğrencinin aile evi kira ise kira sözleşmesi gönderilmelidir.</li><li>Öğrencinin aile evi kendilerinin ise tapu gönderilmelidir.</li><li>Akraba evinde kirasız vs. kalma gibi belgelenemeyen durumları belirtmek için referans üyemiz kendi el yazısıyla, imzalı ve kaşeli bir dilekçe yazabilir.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 9,
      title: "Taşınır/Taşınmaz Mal Beyanları",
      name: "MalBeyanlari",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Taşınır mal beyanı, e-devlette <strong>Emniyet Müdürlüğü</strong> sayfasındaki <strong>Adıma Tescilli Araç Sorgulama</strong> ekranından alınabilir.</li><li>Taşınmaz mal beyanı, e-devlette <strong>Tapu ve Kadastro</strong> sayfasındaki <strong>Tapu Bilgileri Sorgulama</strong> ekranından alınabilir.</li><li>Kişi isminin net olarak göründüğü ekran görüntüsü kabul edilir.</li><li>Eğer bağ, bahçe, tarla, arsa vb. var ise değer tespit belgesi (16. Madde) <strong>zorunludur</strong>.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 10,
      title: "Okuyan Kardeşlerin Öğrenci Belgeleri",
      name: "OkuyanKardeslerinOgrenciBelgeleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 11,
      title: "Öğrencinin Geliri Olmadığına Dair Belge",
      name: "OgrencininGeliriOlmadidiginaDairBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>e-Devletteki <strong>SGK Bilgileri</strong> sayfasından alınabilir.</li><li>Öğrenci 18 yaş altı ise bu belgeye gerek yoktur.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 12,
      title: "Ev Kredisi Borç Dökümü",
      name: "EvKredisiBorcDokumu",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc:
        "<ul><li>Eğer ailenin ödemekte olduğu ev kredisi borcu varsa beyan edilmelidir. Diğer krediler değerlendirmeye <strong>dahil değildir</strong>.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 13,
      title: "Anne ve Babanın Ayrı Olduğunu Gösteren Belge",
      name: "AnneBabaninAyriOldugunuGosterenBelge",
      accept: ".png, .jpeg, .jpg, .gif, .pdf",
      desc: "",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 14,
      title: "Maaş Bordroları ve SGK Dökümleri",
      name: "MaasBordrolariVeSgkDokumleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Anne ve babanın <strong>son 3 aya ait maaş bordrosu</strong> ve e-devletten alınacak <strong>son 6 aya ait özet SGK 4a/4B/4C Dökümü</strong></li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 15,
      title: "Serbest Meslek Gelir Beyannameleri",
      name: "SerbestMeslekGelirBeyannameleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Anne ve/veya baba serbest çalışan ise gelir beyannameleri belirtilmelidir.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 16,
      title: "Tarım Geliri Belgeleri",
      name: "TarimGeliriBelgeleri",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Eğer öğrencinin ailesi gelirini tarımdan elde ediyor ise <strong>çiftçi ilçe tarım ziraat odası gelir durumu belgesi</strong> ve <strong>kaç dönüm arazisi olduğuna dair belge</strong> beyan edilmelidir.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 16,
      title: "Değer Tespit Belgesi",
      name: "GelirTespitBelgesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Eğer taşınmaz mal beyanında ev, arsa, bağ, bahçe vb. çıktıysa ilgili <strong>belediyeden</strong> alınacak <strong>değer tespit belgesi</strong> sunulmalıdır.</li><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 17,
      title: "Son 3 Yılın Not Ortalamaları",
      name: "NotOrtalamalari",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    },
    {
      id: 18,
      title: "Dershane Sözleşmesi",
      name: "DershaneSozlesmesi",
      accept: ".png, .jpeg, .jpg, .gif, .pdf, .zip",
      desc:
        "<ul><li>Tüm belgeler tek bir dosya olarak yüklenmelidir. Bunun için belgeleri sıkıştırabilir (.zip) ya da (.pdf) uzantılı tek bir dosyada birleştirebilirsiniz.</li></ul>",
      loading: false,
      uploadedFileName: "",
      uploadedFilePath: "",
      uploadedFileUrl: "",
      dateUploaded: null as firebase.firestore.Timestamp | null
    }
  ];

  fileRules = [
    (v: File) =>
      !v || v.size <= 10485760 || "Dosya boyutu 10 MB'den küçük olmalıdır."
  ];

  get fileList() {
    switch (this.scholarship.urlCode) {
      case "dershane-destegi":
        return this.fileListDershane;
      default:
        return this.fileListDefault;
    }
  }

  get member() {
    return this.$store.getters["scholarship/member"];
  }

  getFilesSaved() {
    this.fileList.forEach((file, i) => {
      if (this.app.files) {
        this.app.files.forEach(
          (fileSaved: {
            id: number;
            name: string;
            path: string;
            url: string;
            dateCreated: firebase.firestore.Timestamp | null;
          }) => {
            if (file.id == fileSaved.id) {
              this.fileList[i].uploadedFileName = fileSaved.name;
              this.fileList[i].uploadedFilePath = fileSaved.path;
              this.fileList[i].uploadedFileUrl = fileSaved.url;
              this.fileList[i].dateUploaded = fileSaved.dateCreated;
            }
          }
        );
      }
    });
  }

  resetForm() {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();
  }

  setFileNameAndPath(i: number) {
    const file = this.files[i];

    const fileExt = file.name.substring(
      file.name.lastIndexOf("."),
      file.name.length
    );
    const fileName = this.fileList[i].name + fileExt;

    this.fileList[i].uploadedFileName = fileName;

    this.fileList[
      i
    ].uploadedFilePath = `/scholarship-apps/${this.app.id}/${fileName}`;
  }

  getFileListWillSave() {
    const newFileList: {
      id: number;
      title: string;
      name: string;
      path: string;
      url: string;
      dateCreated: firebase.firestore.Timestamp | null;
    }[] = [];

    this.fileList.forEach(file => {
      if (file.uploadedFileName) {
        // Yeni eklenen

        newFileList.push({
          id: file.id,
          title: file.title.replace("*", "").trim(),
          name: file.uploadedFileName,
          path: file.uploadedFilePath,
          url: file.uploadedFileUrl,
          dateCreated: file.dateUploaded
        });
      }
    });

    return newFileList;
  }

  async uploadFile(i: number) {
    const form = this.$refs.form as HTMLFormElement;

    if (!form.validate()) {
      return;
    }

    this.fileList[i].loading = true;

    try {
      const uploadTask = firebase
        .storage()
        .ref()
        .child(this.fileList[i].uploadedFilePath)
        .put(this.files[i]);

      uploadTask.on(
        "state_changed",
        snapshot => {
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        err => {
          this.$notify({
            title: "Hata!",
            text: `${this.fileList[i].title} dosyası yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
            type: "error"
          });
          console.error(
            `${this.fileList[i].title} dosyası yüklenemedi. ${err.code} ${err.message}`
          );
          this.fileList[i].loading = false;
          this.resetForm();
        },
        async () => {
          this.fileList[
            i
          ].uploadedFileUrl = await uploadTask.snapshot.ref.getDownloadURL();

          this.fileList[i].dateUploaded = firebase.firestore.Timestamp.now();

          // Kaydedilecek dosya listesini oluştur
          const newFileList = this.getFileListWillSave();

          await firebase
            .firestore()
            .doc(`/apps/kahev-org/scholarshipApps/${this.app.id}`)
            .update({
              files: newFileList
            });

          this.$notify({
            type: "success",
            title: "İşlem Tamam!",
            text: "Dosya başarıyla eklendi."
          });

          this.fileList[i].loading = false;
          this.resetForm();
        }
      );
    } catch (err) {
      this.$notify({
        title: "Hata!",
        text: `${this.fileList[i].title} dosyası yüklenemedi. Lütfen daha sonra tekrar deneyiniz.`,
        type: "error"
      });
      console.error(err);

      this.fileList[i].loading = false;
      this.resetForm();
    }
  }

  async deleteFile(i: number) {
    try {
      // Dosyayı sil
      const fileRef = firebase.storage().ref(this.fileList[i].uploadedFilePath);
      await fileRef.delete();

      // Belge kaydını sil
      this.fileList[i].uploadedFileName = "";
      this.fileList[i].uploadedFilePath = "";
      this.fileList[i].uploadedFileUrl = "";
      this.fileList[i].dateUploaded = null;

      const newFileList = this.getFileListWillSave();
      newFileList.splice(i, 1);

      await firebase
        .firestore()
        .doc(`/apps/kahev-org/scholarshipApps/${this.app.id}`)
        .update({
          files: newFileList
        });

      this.$notify({
        type: "success",
        title: "İşlem Tamam!",
        text: "Dosya başarıyla silindi."
      });
    } catch (err) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: "Dosya silinemedi! Lütfen daha sonra tekrar deneyiniz."
      });

      console.error(err);
    }
  }

  onFileChange(i: number) {
    this.setFileNameAndPath(i);
    this.uploadFile(i);
  }

  async submitApp() {
    try {
      const status = "inevaluation";
      const statusMessage = {
        subject: "KAHEV Burs Başvurunuz Değerlendiriliyor",
        body: `<p>Merhaba,</p><p><strong>{{tarih}}</strong> tarihinde <strong>{{ogrenci}}</strong> adına yaptığınız <strong>{{id}}</strong> kodu burs başvurunuzun durumu <strong>değerlendiriliyor</strong> olarak güncellendi.</p><p>İyi günler!</p><p>KAHEV</p>`
      };

      await firebase
        .firestore()
        .doc(`/apps/kahev-org/scholarshipApps/${this.app.id}`)
        .update({
          status: "inevaluation",
          statusMessage: statusMessage
        });

      this.app.status = status;
      this.app.statusMessage = statusMessage;

      this.$notify({
        title: "İşlem Tamam!",
        text: "Başvurunuz değerlendirme için gönderildi.",
        type: "success"
      });
    } catch (err) {
      this.$notify({
        title: "Hata!",
        text: "Başvurunuz gönderilemedi. Lütfen daha sonra tekrar deneyiniz.",
        type: "error"
      });
      console.error(err);
    }
  }

  async mounted() {
    this.getFilesSaved();
  }
}
