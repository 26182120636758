









































































import { Component, Vue, Watch } from "vue-property-decorator";
import "@/types";
import firebase from "@/plugins/firebase";
import AppForm from "./AppForm.vue";
import FileUploader from "./FileUploader.vue";
import AppResult from "./AppResult.vue";
import MemberCard from "@/layouts/scholarship/application/MemberCard.vue";

@Component({
  metaInfo: {
    title: "Online Burs Başvurusu"
  },
  components: {
    AppForm,
    FileUploader,
    AppResult
  }
})
export default class ScholarshipAppView extends Vue {
  appId = "";
  app: ScholarshipApplication | null = null;
  scholarship: Scholarship | null = null;
  tab = null;

  get alertType() {
    switch (this.app?.status) {
      case "accepted":
        return "success";
      case "rejected":
        return "error";
      default:
        return "warning";
    }
  }

  formatStatusMsgBody() {
    return this.app?.statusMessage?.body
      .replace(/{{id}}/g, this.app?.id)
      .replace(
        /{{tarih}}/g,
        this.app?.dateCreated.toDate().toLocaleString("tr-TR")
      )
      .replace(/{{uye}}/g, this.app?.member.fullName)
      .replace(/{{ogrenci}}/g, this.app?.form.student.fullName);
  }

  @Watch("app.status")
  updateMemberCard() {
    const memberCard = this.$refs.memberCard as MemberCard;
    if (memberCard) {
      memberCard.getPastApplications();
    }
  }

  @Watch("$route")
  watchRoute() {
    this.init();
  }

  async init() {
    this.appId = this.$route.params.id;

    const appDoc = await firebase
      .firestore()
      .doc(`/apps/kahev-org/scholarshipApps/${this.appId}`)
      .get();

    this.app = appDoc.data() as ScholarshipApplication;
    this.app.id = appDoc.id;

    const scholarshipDoc = await firebase
      .firestore()
      .doc(`/apps/kahev-org/scholarships/${this.app.scholarshipId}`)
      .get();

    this.scholarship = scholarshipDoc.data() as Scholarship;
    this.scholarship.id = scholarshipDoc.id;
  }

  mounted() {
    this.init();
  }
}
