

























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
//import firebase from "@/plugins/firebase";
import "@/types";

const AppResultBase = Vue.extend({
  props: {
    app: {
      type: Object,
      required: true
    },
    scholarship: {
      type: Object,
      required: true
    }
  }
});

@Component
export default class AppResult extends AppResultBase {
  eduLevels = [
    "Okul Öncesi",
    "İlköğretim",
    "Lise",
    "Üniversite",
    "Tıp/Diş Hekimliği"
  ];

  gender = ["Kadın", "Erkek"];

  schoolTypes = [
    "Okul Öncesi",
    "İlköğretim",
    "Lise",
    "Üniversite (2 Yıllık)",
    "Üniversite (4 Yıllık)",
    "Üniversite (5 Yıllık)",
    "Tıp/Diş Hekimliği"
  ];

  residentialTypes = ["Aile Yanı", "Ev", "Devlet Yurdu", "Özel Yurt"];

  chronicIllness = ["Var", "Yok"];

  isAlive = ["Hayatta", "Hayatta Değil"];

  get motherIsAlive() {
    return this.app.form.family.mother.isAlive === "Hayatta";
  }

  get fatherIsAlive() {
    return this.app.form.family.father.isAlive === "Hayatta";
  }
}
